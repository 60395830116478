import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBenchmark_Model_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBenchmark_Model_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsUserDisabled: any[];
  IsUserDisabled_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetOngoingBenchmarkModelsAsJson: any[];
  GetOngoingBenchmarkModelsAsJson_dummy: any[];
  GetGCPAuthToken: string;
  GetGCPAuthToken_dummy: string;
  CheckVertexAiCustomJobsStatus: string;
  CheckVertexAiCustomJobsStatus_dummy: string;
  UpdateOngoingBenchmarkModels: number;
  UpdateOngoingBenchmarkModels_dummy: number;
  GetBenchmarkModels: any[];
  GetBenchmarkModels_dummy: any[];
  DeleteBenchmarkModel: any[];
  DeleteBenchmarkModel_dummy: any[];
  BenchmarkModelResultsExist: any[];
  BenchmarkModelResultsExist_dummy: any[];
  GetTrainingResultsFromBucket: any;
  GetTrainingResultsFromBucket_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  UpdateBenchmarkModelResults: number;
  UpdateBenchmarkModelResults_dummy: number;
  CancelVertexAiCustomJob: any;
  CancelVertexAiCustomJob_dummy: any;
  UpdateBenchmarkModelAfterTrainingCancel: number;
  UpdateBenchmarkModelAfterTrainingCancel_dummy: number;
  GetBenchmarkModelProjectsAsJson: any[];
  GetBenchmarkModelProjectsAsJson_dummy: any[];
  SaveJsonToBucket: any;
  SaveJsonToBucket_dummy: any;
  CreateVertexAiCustomJob: any;
  CreateVertexAiCustomJob_dummy: any;
  UpdateBenchmarkModelAfterTrainingStart: number;
  UpdateBenchmarkModelAfterTrainingStart_dummy: number;
  isComp673617Enabled: "enabled" | "disabled";
  isComp693958Enabled: "enabled" | "disabled";
  isComp427546Enabled: "enabled" | "disabled";
  isComp474972Enabled: "enabled" | "disabled";
  isComp721509Enabled: "enabled" | "disabled";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Benchmark_Model_List_ScreenBase extends React.PureComponent<IBenchmark_Model_List_ScreenProps, any> {
  benchmark_model_list_684391_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "17b5d42d-84f5-4e27-ae4c-f605028a2fac",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 253664, PropertyName: "value", Value: "Efor Kestirim Modelleri" },
        { Id: 837133, PropertyName: "label", Value: "Ekle" },
        { Id: 347356, PropertyName: "label", Value: "Yenile" },
        { Id: 609277, PropertyName: "title", Value: "İşlem" },
        { Id: 400146, PropertyName: "title", Value: "Model Adı" },
        { Id: 747474, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 984952, PropertyName: "title", Value: "Model Tipi" },
        { Id: 571799, PropertyName: "value", Value: "[datafield:typename]" },
        { Id: 678432, PropertyName: "title", Value: "Projeler" },
        { Id: 960699, PropertyName: "value", Value: "[datafield:projectnames]" },
        { Id: 112682, PropertyName: "title", Value: "Durum" },
        { Id: 596925, PropertyName: "value", Value: "[datafield:statusname]" },
        { Id: 100043, PropertyName: "title", Value: "Başlangıç Tarihi" },
        { Id: 118931, PropertyName: "value", Value: "[datafield:starttime]" },
        { Id: 724300, PropertyName: "title", Value: "Bitiş Tarihi" },
        { Id: 300949, PropertyName: "value", Value: "[datafield:endtime]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.benchmark_model_list_684391_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsUserDisabled: [],
      NAVIGATE: "",
      MyContext: [],
      GetOngoingBenchmarkModelsAsJson: [],
      GetGCPAuthToken: "",
      CheckVertexAiCustomJobsStatus: "",
      UpdateOngoingBenchmarkModels: 0,
      GetBenchmarkModels: [],
      DeleteBenchmarkModel: [],
      BenchmarkModelResultsExist: [],
      GetTrainingResultsFromBucket: "",
      Notify: false,
      UpdateBenchmarkModelResults: 0,
      CancelVertexAiCustomJob: "",
      UpdateBenchmarkModelAfterTrainingCancel: 0,
      GetBenchmarkModelProjectsAsJson: [],
      SaveJsonToBucket: "",
      CreateVertexAiCustomJob: "",
      UpdateBenchmarkModelAfterTrainingStart: 0,
      isComp673617Enabled: "disabled",
      isComp693958Enabled: "disabled",
      isComp427546Enabled: "disabled",
      isComp474972Enabled: "disabled",
      isComp721509Enabled: "disabled",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("benchmark_model_list", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.Benchmark_Model_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("benchmark_model_list", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("benchmark_model_list", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Benchmark_Model_ListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.Benchmark_Model_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  Benchmark_Model_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Benchmark_Model_List/Benchmark_Model_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsUserDisabled = result?.data.isUserDisabled;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_ListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsUserDisabled?.length > 0
            ? stateVars.IsUserDisabled[0].result
            : this.state.IsUserDisabled?.length > 0
            ? this.state.IsUserDisabled[0].result
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Benchmark_Model_ListPageInit2_,
        "Benchmark_Model_List",
        "DisabledAccount",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      gcpAuthToken_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingBenchmarkModelsAsJson?.length > 0
            ? stateVars.GetOngoingBenchmarkModelsAsJson[0].ongoingBenchmarkModels
            : this.state.GetOngoingBenchmarkModelsAsJson?.length > 0
            ? this.state.GetOngoingBenchmarkModelsAsJson[0].ongoingBenchmarkModels
            : null
        ),
        "string"
      ),
      OngoingModelStatusResultsJson_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      CompanyId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_List/Benchmark_Model_ListPageInit2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetOngoingBenchmarkModelsAsJson = result?.data.getOngoingBenchmarkModelsAsJson;
    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingBenchmarkModels = result?.data.updateOngoingBenchmarkModels;
    stateVars.GetBenchmarkModels = result?.data.getBenchmarkModels;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_ListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_684391 = this.state.GetBenchmarkModels;
    stateVars.isComp673617Enabled =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "statusName"),
        "Bitti"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "statusName"),
          "Devam Ediyor"
        )) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp693958Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp427546Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "statusName"),
        "Bitti"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp474972Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp721509Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Benchmark_Model_ListComponent_837133_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Benchmark_Model_Form",
      "ModelId",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "Benchmark_Model_Form",
      "ModelTypeId",
      ReactSystemFunctions.convertToTypeByName("6f919272-ee48-496e-a624-3c3ce92ffe7a", "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Benchmark_Model_List",
      "Benchmark_Model_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "130346",
      null,
      null,
      "true",
      "70vw",
      "80vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_ListComponent_347356_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingBenchmarkModelsAsJson?.length > 0
            ? stateVars.GetOngoingBenchmarkModelsAsJson[0].ongoingBenchmarkModels
            : this.state.GetOngoingBenchmarkModelsAsJson?.length > 0
            ? this.state.GetOngoingBenchmarkModelsAsJson[0].ongoingBenchmarkModels
            : null
        ),
        "string"
      ),
      OngoingModelStatusResultsJson_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      CompanyId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_List/Benchmark_Model_ListComponent_347356_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.GetOngoingBenchmarkModelsAsJson = result?.data.getOngoingBenchmarkModelsAsJson;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingBenchmarkModels = result?.data.updateOngoingBenchmarkModels;
    stateVars.GetBenchmarkModels = result?.data.getBenchmarkModels;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_ListComponent_347356_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_347356_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_684391 = this.state.GetBenchmarkModels;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_ListComponent_673617_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Benchmark_Model_Form",
      "ModelId",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "Benchmark_Model_Form",
      "ModelTypeId",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "typeId"),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Benchmark_Model_List",
      "Benchmark_Model_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "631825",
      null,
      null,
      "true",
      "70vw",
      "80vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_ListComponent_693958_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BenchmarkModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "Guid"
      ),
      CompanyId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1c8f8e89_0cfa_70fb_757c_4f30b8740004_confirmation",
        this.defaultML,
        "Efor kestirim modelini silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-roboto"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_693958_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteBenchmarkModel = result?.data.deleteBenchmarkModel;
      stateVars.GetBenchmarkModels = result?.data.getBenchmarkModels;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_693958_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_693958_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_684391 = this.state.GetBenchmarkModels;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_ListComponent_427546_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BenchmarkModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_List/Benchmark_Model_ListComponent_427546_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BenchmarkModelResultsExist = result?.data.benchmarkModelResultsExist;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BenchmarkModelResultsExist?.length > 0
            ? stateVars.BenchmarkModelResultsExist[0].modelResultsExist
            : this.state.BenchmarkModelResultsExist?.length > 0
            ? this.state.BenchmarkModelResultsExist[0].modelResultsExist
            : null
        ),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_427546_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      modelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "string"
      ),
      gcpAccessToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      folderName_0: ReactSystemFunctions.convertToTypeByName("effort_stats", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BenchmarkModelResultsExist?.length > 0
            ? stateVars.BenchmarkModelResultsExist[0].modelResultsExist
            : this.state.BenchmarkModelResultsExist?.length > 0
            ? this.state.BenchmarkModelResultsExist[0].modelResultsExist
            : null
        ),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_427546_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetTrainingResultsFromBucket = result?.data.getTrainingResultsFromBucket;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "error"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Mmre_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mmre),
        "number"
      ),
      MdMre_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mdmre),
        "number"
      ),
      Pred30_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.pred30),
        "number"
      ),
      Mae_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mae),
        "number"
      ),
      NMae_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.nmae),
        "number"
      ),
      BenchmarkModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_427546_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateBenchmarkModelResults = result?.data.updateBenchmarkModelResults;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_427546_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetBenchmarkModels = result?.data.getBenchmarkModels;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BenchmarkModelResultsExist?.length > 0
            ? stateVars.BenchmarkModelResultsExist[0].modelResultsExist
            : this.state.BenchmarkModelResultsExist?.length > 0
            ? this.state.BenchmarkModelResultsExist[0].modelResultsExist
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "mmre"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MdMMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "mdMRE"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "PRED30",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "preD30"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "ModelName",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "name"),
          "string"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "mae"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "NMAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "nMae"),
          "number"
        )
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Benchmark_Model_ListComponent_427546_onClick7_,
        "Benchmark_Model_List",
        "Model_Training_Result",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "572799",
        null,
        null,
        "true",
        "800px",
        "70vh",
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      ) &&
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.BenchmarkModelResultsExist?.length > 0
            ? stateVars.BenchmarkModelResultsExist[0].modelResultsExist
            : this.state.BenchmarkModelResultsExist?.length > 0
            ? this.state.BenchmarkModelResultsExist[0].modelResultsExist
            : null
        ),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mmre),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MdMMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mdmre),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "PRED30",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.pred30),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "ModelName",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "name"),
          "string"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mae),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "NMAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.nmae),
          "number"
        )
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Benchmark_Model_ListComponent_427546_onClick8_,
        "Benchmark_Model_List",
        "Model_Training_Result",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "969391",
        null,
        null,
        "true",
        "800px",
        "70vh",
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_427546_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      stateVars.dataSource_684391 = this.state.GetBenchmarkModels;

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Benchmark_Model_ListComponent_474972_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_List/Benchmark_Model_ListComponent_474972_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_ListComponent_474972_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_474972_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      customJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "vertexCustomJobName"),
        "string"
      ),
      BenchmarkModelId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "Guid"
      ),
      CompanyId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5a9c9d2b_8782_b199_5b45_a908e3ae7363_confirmation",
        this.defaultML,
        "Benchmark model eğitimini iptal etmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-roboto"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_474972_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CancelVertexAiCustomJob = result?.data.cancelVertexAiCustomJob;
      stateVars.UpdateBenchmarkModelAfterTrainingCancel = result?.data.updateBenchmarkModelAfterTrainingCancel;
      stateVars.GetBenchmarkModels = result?.data.getBenchmarkModels;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_474972_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_474972_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "86e49d35_97e4_284b_ccb9_d730417ca73d_notify",
        this.defaultML,
        "Benchmark modeli eğitim işlemi iptal edildi."
      ),
      ReactSystemFunctions.convertToTypeByName("success", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("5", "number"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("0", "number")
    );

    stateVars.dataSource_684391 = this.state.GetBenchmarkModels;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_ListComponent_721509_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BenchmarkModelId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "Guid"
      ),
      jsonString_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetBenchmarkModelProjectsAsJson?.length > 0
            ? stateVars.GetBenchmarkModelProjectsAsJson[0].benchmarkProjectsAsJson
            : this.state.GetBenchmarkModelProjectsAsJson?.length > 0
            ? this.state.GetBenchmarkModelProjectsAsJson[0].benchmarkProjectsAsJson
            : null
        ),
        "string"
      ),
      gcpAccessToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      prefix_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "name"),
        "string"
      ),
      folderName_2: ReactSystemFunctions.convertToTypeByName("effort_data", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_List/Benchmark_Model_ListComponent_721509_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.GetBenchmarkModelProjectsAsJson = result?.data.getBenchmarkModelProjectsAsJson;
    stateVars.SaveJsonToBucket = result?.data.saveJsonToBucket;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result), "error")
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("4", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      task_0: ReactSystemFunctions.convertToTypeByName("eff_train", "string"),
      modelBucketPath_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      jsonBucketPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.bucketPath),
        "string"
      ),
      targetKey_0: ReactSystemFunctions.convertToTypeByName("effort", "string"),
      jobId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "string"
      ),
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserID
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserID
            : null
        ),
        "string"
      ),
      modelTypeCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "typeCode"),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_721509_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CreateVertexAiCustomJob = result?.data.createVertexAiCustomJob;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      StartTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      VertexCustomJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.customJob?.name),
        "string"
      ),
      BenchmarkModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_model_list_684391_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_721509_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateBenchmarkModelAfterTrainingStart = result?.data.updateBenchmarkModelAfterTrainingStart;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "error"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("3", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "02ad7c9a_a7e1_e9f3_cb67_bb95f5767981_notify",
          this.defaultML,
          "Benchmark model eğitim işlemi başladı."
        ),
        ReactSystemFunctions.convertToTypeByName("success", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_List/Benchmark_Model_ListComponent_721509_onClick6_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetBenchmarkModels = result?.data.getBenchmarkModels;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick7_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_ListComponent_721509_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_ListComponent_721509_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      stateVars.dataSource_684391 = this.state.GetBenchmarkModels;

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 130346, 631825, 572799, 969391] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Benchmark_Model_ListPageInit();
    }
    if (diId == 572799) {
      isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick7_();
      if (isErrorOccurred) return true;
    } else if (diId == 969391) {
      isErrorOccurred = await this.Benchmark_Model_ListComponent_427546_onClick8_();
      if (isErrorOccurred) return true;
    }
  }
}
